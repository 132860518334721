import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Typography, Button, Box, Paper } from "@mui/material";
import { styled } from "@mui/system";

import useAuth from "../../hooks/Auth";
import LoginModal from "./LoginModal";
import RegisterModal from "./RegisterModal";

const LoginContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  minHeight: "100vh",
}));

const LoginPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  textAlign: "center",
}));

const WelcomeTypography = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    fontSize: "1.9rem",
  },
}));

const LoginButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginRight: theme.spacing(2),
}));

const RegisterButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

function LoginPage() {
  const { isAuthenticated, user } = useAuth();
  const navigate = useNavigate();

  const [openLoginModal, setOpenLoginModal] = useState(false);
  const [openRegisterModal, setOpenRegisterModal] = useState(false);

  useEffect(() => {
    if (isAuthenticated()) {
      navigate("/", { replace: true });
    }
  }, [user, navigate]);

  const handleOpenLoginModal = () => {
    setOpenLoginModal(true);
  };

  const handleCloseLoginModal = () => {
    setOpenLoginModal(false);
  };

  const handleOpenRegisterModal = () => {
    setOpenRegisterModal(true);
  };

  const handleCloseRegisterModal = () => {
    setOpenRegisterModal(false);
  };

  return (
    <LoginContainer>
      <LoginPaper elevation={3}>
        <WelcomeTypography variant="h4" gutterBottom>
          Witaj w SBox!
        </WelcomeTypography>
        <Typography variant="body1" gutterBottom>
          Zaloguj się lub zarejestruj, aby kontynuować.
        </Typography>
        <LoginButton
          variant="contained"
          color="primary"
          onClick={handleOpenLoginModal}
        >
          Zaloguj się
        </LoginButton>
        <RegisterButton
          variant="outlined"
          color="secondary"
          onClick={handleOpenRegisterModal}
        >
          Zarejestruj się
        </RegisterButton>
        <LoginModal open={openLoginModal} onClose={handleCloseLoginModal} />
        <RegisterModal
          open={openRegisterModal}
          onClose={handleCloseRegisterModal}
        />
      </LoginPaper>
    </LoginContainer>
  );
}

export default LoginPage;
