import React from "react";
import { Box, Typography, Link, Button } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import useAuth from "../hooks/Auth";

const FooterContainer = {
  py: 2,
  px: 3,
  mt: "auto",
  textAlign: "center",
  maxWidth: "1200px",
  margin: "0 auto",
  borderTop: "1px solid #e0e0e0",
};

const FooterText = {
  fontSize: "0.9rem",
  color: "#6c757d",
};

function Footer() {
  const { isAuthenticated, logout, user } = useAuth();

  const handleLogout = () => {
    logout();
  };

  return (
    <Box sx={FooterContainer}>
      <Typography sx={FooterText}>
        {"© "}
        {new Date().getFullYear()}
        {" SBox. Fabian Zwoliński"}
      </Typography>

      {isAuthenticated() && (
        <>
          <Box sx={{ mt: 1 }}>
            <Link
              component={RouterLink}
              to="/"
              style={{ userSelect: "none" }}
              color="inherit"
              underline="none"
              sx={{ mx: 1 }}
            >
              SBox
            </Link>
            <Link
              component={RouterLink}
              to="/about"
              style={{ userSelect: "none" }}
              color="inherit"
              underline="none"
              sx={{ mx: 1 }}
            >
              O SBox
            </Link>
            <Link
              component={RouterLink}
              to="/settings"
              style={{ userSelect: "none" }}
              color="inherit"
              underline="none"
              sx={{ mx: 1 }}
            >
              Ustawienia
            </Link>
          </Box>

          <Typography sx={{ ...FooterText, mt: 1 }}>
            Zalogowany jako: {user.email}
          </Typography>
          <Button
            variant="text"
            color="primary"
            onClick={handleLogout}
            sx={{ mt: 1, textTransform: "none" }}
          >
            Wyloguj
          </Button>
        </>
      )}
    </Box>
  );
}

export default Footer;
