import { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from "jwt-decode";

import AlertContext from '../context/AlertContext';
import api from '../api/axios';
import User from '../models/User';

function useAuth() {
  const navigate = useNavigate();

  const [user, setUser] = useState(() => {
    const token = localStorage.getItem('JWT');
    if (token) {
      try {
        const decodedToken = jwtDecode(token, );
        return new User(decodedToken.email, decodedToken.pubId);
      } catch (error) {
        console.error('Invalid token:', error);
        return null;
      }
    }
    return null;
  });

  const { setAlert } = useContext(AlertContext);

  const isAuthenticated = () => {
    return user !== null;
  };

  const login = async (email, password) => {
    try {
      const response = await api.post('/api/login', { email, password });
      
      if (response.status === 200) {
        const token = response.data.token;
        localStorage.setItem('JWT', token);
        const decodedToken = jwtDecode(token);
        const userInstance = new User(decodedToken.email, decodedToken.pubId);
        setUser(userInstance);
        navigate(0);
        setAlert([true, 'Zalogowano pomyślnie!', 'success']);
      } else {
        console.error('Login failed:', response.data.message);
        setAlert([true, 'Nieprawidłowy email lub hasło', 'error']);
      }
    } catch (error) {
      console.log("Login error message: ", error.response.data.message);
      setAlert([true, 'Nieprawidłowy email lub hasło', 'error']);
    }
  };

  const register = async (email, password, pubId) => {
    try {
      const response = await api.post('/api/register', { email, password, pubId });
  
      if (response.status === 200) {
        const token = response.data.token;
        localStorage.setItem('JWT', token);
        const decodedToken = jwtDecode(token);
        const userInstance = new User(decodedToken.email, decodedToken.pubId);
        setUser(userInstance);
        navigate(0);
        setAlert([true, 'Zarejestrowano pomyślnie!', 'success']);
      } else {
        console.error('Registration failed:', response.data.message);
        setAlert([true, 'Błąd podczas rejestracji', 'error']);
      }
    } catch (error) {
      console.log("Registration error message: ", error.response.data.message);
      setAlert([true, 'Błąd podczas rejestracji', 'error']);
      // console.error('Registration error:', error);
    }
  };
  
  const logout = () => {
    localStorage.removeItem('JWT');
    setUser(null);
    navigate(0);
  };

  return { isAuthenticated, user, setUser, login, register, logout };
}

export default useAuth;