import React from "react";
import { Typography, Box, Paper } from "@mui/material";
import { styled } from "@mui/system";

const AboutContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: theme.spacing(4),

  [theme.breakpoints.down("sm")]: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

const AboutPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  maxWidth: "800px",
  marginBottom: theme.spacing(4),

  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(0),
  },
}));

const AboutTitle = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  userSelect: "none",
}));

const AboutDescription = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

const AboutFeature = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(1),
}));

function About() {
  return (
    <AboutContainer>
      <AboutTitle variant="h4" gutterBottom>
        O projekcie SBox
      </AboutTitle>
      <AboutPaper elevation={3}>
        <AboutDescription variant="body1" paragraph>
          SBox to innowacyjna aplikacja webowa stworzona z myślą o uczniach, aby
          ułatwić im zarządzanie zadaniami i wydarzeniami szkolnymi. Dzięki
          intuicyjnemu interfejsowi i bogatej funkcjonalności, SBox pozwala na
          efektywne planowanie i organizację życia szkolnego.
        </AboutDescription>
        <AboutDescription variant="body1" paragraph>
          Głównym celem projektu SBox jest dostarczenie uczniom narzędzia, które
          pomoże im być na bieżąco z nadchodzącymi sprawdzianami, pracami
          domowymi, projektami i innymi istotnymi wydarzeniami szkolnymi.
          Aplikacja oferuje przejrzysty i czytelny interfejs, który umożliwia
          łatwe dodawanie, przeglądanie i zarządzanie zadaniami oraz
          wydarzeniami.
        </AboutDescription>
        <AboutDescription variant="body1" paragraph>
          Kluczowe funkcjonalności projektu SBox obejmują:
        </AboutDescription>
        <AboutFeature variant="body1">
          - Dodawanie i edycja zadań oraz wydarzeń szkolnych
        </AboutFeature>
        <AboutFeature variant="body1">
          - Przejrzysty podgląd nadchodzących i minionych wydarzeń
        </AboutFeature>
        <AboutFeature variant="body1">
          - Możliwość dołączania plików i materiałów do zadań
        </AboutFeature>
        <AboutFeature variant="body1">
          - Powiadomienia o zbliżających się terminach
        </AboutFeature>
        <AboutFeature variant="body1">
          - Współpraca w grupach i dzielenie się zadaniami
        </AboutFeature>
        <AboutDescription variant="body1" paragraph>
          SBox został zbudowany przy użyciu nowoczesnych technologii webowych,
          takich jak React.js dla interfejsu użytkownika oraz Golang dla backend
          API. Aplikacja korzysta również z bazy danych MySQL do przechowywania
          danych użytkowników, zadań i wydarzeń.
        </AboutDescription>
        <AboutDescription variant="body1" paragraph>
          Projekt SBox jest stale rozwijany i ulepszany, aby zapewnić uczniom
          jak najlepsze doświadczenie w zarządzaniu swoim życiem szkolnym. W
          przyszłości planowane są kolejne funkcjonalności, takie jak integracja
          z kalendarzem, powiadomienia push oraz dostosowywanie wyglądu
          aplikacji do preferencji użytkownika.
        </AboutDescription>
        <AboutDescription variant="body1" paragraph>
          Jeśli masz jakieś sugestie lub pomysły dotyczące projektu SBox,
          skontaktuj się z nami. Chętnie poznamy Twoją opinię i weźmiemy ją pod
          uwagę w dalszym rozwoju aplikacji.
        </AboutDescription>
      </AboutPaper>
    </AboutContainer>
  );
}

export default About;
