import React, { useEffect, useState, useContext } from "react";
import {
  Typography,
  Button,
  Box,
  Paper,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { fontSize, styled } from "@mui/system";
import useAuth from "../../hooks/Auth";
import Event from "./Event";
import AddEventModal from "./AddEventModal";
import api from "../../api/axios";
import AlertContext from "../../context/AlertContext";
import { parseLocalDate } from "../../formatDateTime";
import upcomingEventsIcon from "../../assets/upcoming_events.png";
import pastEventsIcon from "../../assets/past_events.png";

const StyledPubId = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    fontSize: "2rem",
  },
}));

const EventListContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: theme.spacing(4),

  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(2, 1, 2, 1),
  },
}));

const EventSection = styled(Paper)(({ theme }) => ({
  width: "100%",
  padding: theme.spacing(2),
  marginBottom: theme.spacing(4),

  [theme.breakpoints.down("sm")]: {
    marginBottom: theme.spacing(0),
  },
}));

const PastEventSection = styled(Paper)(({ theme }) => ({
  width: "100%",
  marginBottom: theme.spacing(4),
  [theme.breakpoints.down("sm")]: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(1),
  },
}));

const StyledIcon = styled("img")(({ theme }) => ({
  width: "24px",
  height: "24px",
  marginRight: "10px",

  [theme.breakpoints.down("sm")]: {
    width: "20px",
    height: "20px",
    marginRight: "10px",
  },
}));

const StyledEventTitle = styled(Typography)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  fontWeight: "bold",
  userSelect: "none",

  [theme.breakpoints.down("sm")]: {
    fontSize: "1.4rem",
  },
}));

function EventList() {
  const { user, isAuthenticated } = useAuth();
  const [events, setEvents] = useState([]);
  const [openAddModal, setOpenAddModal] = useState(false);
  const { setAlert } = useContext(AlertContext);

  const fetchEvents = async () => {
    try {
      const response = await api.get("/api/events");
      setEvents(response.data);
    } catch (error) {
      console.error("Error fetching events:", error);
      setAlert([true, "Błąd podczas pobierania wydarzeń", "error"]);
    }
  };

  useEffect(() => {
    if (user) {
      fetchEvents();
    }
  }, [user]);

  const handleOpenAddModal = () => {
    setOpenAddModal(true);
  };

  const handleCloseAddModal = () => {
    setOpenAddModal(false);
  };

  const upcomingEvents = events
    .filter((event) => new Date(parseLocalDate(event.dateTime)) > new Date())
    .sort((a, b) => new Date(parseLocalDate(a.dateTime)) - new Date(parseLocalDate(b.dateTime)));

  const pastEvents = events
    .filter((event) => new Date(parseLocalDate(event.dateTime)) <= new Date())
    .sort((a, b) => new Date(parseLocalDate(b.dateTime)) - new Date(parseLocalDate(a.dateTime)));

  return (
    <EventListContainer>
      {isAuthenticated() && (
        <StyledPubId
          variant="h3"
          gutterBottom
          style={{ marginBottom: 40, userSelect: "none" }}
        >
          <b>{user.pubId}</b>
        </StyledPubId>
      )}
      <Button
        variant="contained"
        color="primary"
        onClick={handleOpenAddModal}
        style={{ marginBottom: 25 }}
      >
        Dodaj wydarzenie
      </Button>

      <EventSection elevation={0} sx={{ backgroundColor: "transparent" }}>
        <StyledEventTitle
          variant="h5"
          gutterBottom
          sx={{ marginBottom: { xs: 3, sm: 4 } }}
        >
          <StyledIcon src={upcomingEventsIcon} alt="Upcoming Events Icon" />
          Nadchodzące wydarzenia
        </StyledEventTitle>

        {upcomingEvents.map((event) => (
          <Event
            key={event.ID}
            event={event}
            onUpdateEvents={fetchEvents}
            eventReadOnly={false}
          />
        ))}
      </EventSection>

      <PastEventSection
        elevation={0}
        sx={{ backgroundColor: "transparent", marginTop: 4 }}
      >
        <Accordion sx={{ backgroundColor: "#fff", boxShadow: "none" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            sx={{ fontWeight: "bold" }}
          >
            <StyledEventTitle variant="h5" gutterBottom>
              <StyledIcon src={pastEventsIcon} alt="Past Events Icon" />
              Wydarzenia z przeszłości
            </StyledEventTitle>
          </AccordionSummary>
          <AccordionDetails>
            {pastEvents.map((event) => (
              <Event
                key={event.ID}
                event={event}
                onUpdateEvents={fetchEvents}
                eventReadOnly={false}
              />
            ))}
          </AccordionDetails>
        </Accordion>
      </PastEventSection>

      <AddEventModal
        open={openAddModal}
        onClose={handleCloseAddModal}
        onUpdateEvents={fetchEvents}
      />
    </EventListContainer>
  );
}

export default EventList;
