import { Typography } from "@mui/material";

export const formatDateTime = (dateString) => {
  const date = new Date(parseLocalDate(dateString));

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");

  const dayOfWeek = getDayOfWeek(date, 2);

  // return `${day}.${month}.${year}  ${hours}:${minutes} \u2022 ${dayOfWeek}`;
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <Typography
        variant="body2"
        style={{ userSelect: "none", fontSize: "inherit" }}
      >
        {dayOfWeek} {day}.{month}.{year}
      </Typography>
      <span
        style={{ marginLeft: "10px", marginRight: "10px", userSelect: "none" }}
      >
        •
      </span>
      <Typography
        variant="body2"
        style={{ userSelect: "none", fontSize: "inherit" }}
      >
        {hours}:{minutes}
      </Typography>
    </div>
  );
};

export function getDayOfWeek(date) {
  const daysOfWeek = [
    "Niedziela",
    "Poniedziałek",
    "Wtorek",
    "Środa",
    "Czwartek",
    "Piątek",
    "Sobota",
  ];
  const dayIndex = date.getDay();
  return daysOfWeek[dayIndex];
}

export function parseLocalDate(dateString) {
  return dateString.replace('T', ' ').replace('Z', '');
}