import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
} from "@mui/material";
import { styled } from "@mui/system";

import useAuth from "../../hooks/Auth";

const StyledDialog = styled(Dialog)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    "& .MuiDialog-paper": {
      margin: theme.spacing(1.5),
      width: "100%",
    },
  },
}));

function RegisterModal({ open, onClose }) {
  const { register } = useAuth();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [pubId, setPubId] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");

  const validateEmail = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setEmailError("Nieprawidłowy format adresu email");
    } else {
      setEmailError("");
    }
  };

  const validatePassword = () => {
    if (password.length < 5) {
      setPasswordError("Hasło musi mieć co najmniej 5 znaków");
    } else {
      setPasswordError("");
    }
  };

  const handleRegister = () => {
    validateEmail();
    validatePassword();

    if (
      emailError === "" &&
      passwordError === "" &&
      email.trim().length !== 0 &&
      password.trim().length !== 0
    ) {
      register(email, password, pubId);
      onClose();
    }
  };

  return (
    <StyledDialog open={open} onClose={onClose}>
      <DialogTitle>
        <b>Rejestracja</b>
      </DialogTitle>
      <DialogContent>
        <TextField
          label="Email"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          fullWidth
          margin="normal"
          onBlur={validateEmail}
          error={emailError !== ""}
          helperText={emailError}
        />
        <TextField
          label="Hasło"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          fullWidth
          margin="normal"
          onBlur={validatePassword}
          error={passwordError !== ""}
          helperText={passwordError}
        />
        <TextField
          label="PubID grupy"
          value={pubId}
          onChange={(e) => setPubId(e.target.value)}
          fullWidth
          margin="normal"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Anuluj</Button>
        <Button onClick={handleRegister} variant="outlined" color="secondary">
          Zarejestruj
        </Button>
      </DialogActions>
    </StyledDialog>
  );
}

export default RegisterModal;
