import React, { useState, useRef, useContext } from "react";
import { CirclePicker } from "react-color";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Box,
  Chip,
} from "@mui/material";

import api from "../../api/axios";
import AlertContext from "../../context/AlertContext";

function AddEventModal({ open, onClose, onUpdateEvents }) {
  const [title, setTitle] = useState("");
  const [date, setDate] = useState("");
  const [description, setDescription] = useState("");

  const [files, setFiles] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const fileInputRef = useRef(null);

  const [titleError, setTitleError] = useState("");
  const [dateError, setDateError] = useState("");

  const [color, setColor] = useState("#FFFFFF");

  const { setAlert } = useContext(AlertContext);

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    setFiles(files);
    setSelectedFiles(files.map((file) => file.name));
  };

  const handleAddFiles = () => {
    fileInputRef.current.click();
  };

  const validateTitle = () => {
    if (title.trim().length === 0) {
      setTitleError("Tytuł jest wymagany");
    } else {
      setTitleError("");
    }
  };

  const validateDate = () => {
    if (!date) {
      setDateError("Data jest wymagana");
    } else {
      setDateError("");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    validateTitle();
    validateDate();

    if (
      titleError === "" &&
      dateError === "" &&
      title.trim().length !== 0 &&
      date
    ) {
      const formData = new FormData();
      formData.append("title", title);
      formData.append("dateTime", date);
      formData.append("description", description.trim());
      formData.append("color", color);
      files.forEach((file) => {
        formData.append("files", file);
      });

      try {
        await api.post("/api/events", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });

        setTitle("");
        setDate("");
        setDescription("");
        setFiles([]);
        setSelectedFiles([]);

        onUpdateEvents();
        onClose();
        setAlert([true, "Dodano Wydarzenie!", "success"]);
      } catch (error) {
        console.error("Error adding event:", error);
        setAlert([true, "Wystąpił błąd podczas dodawania eventu!", "error"]);
      }
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle style={{ userSelect: "none" }}>
        Dodaj nowe wydarzenie
      </DialogTitle>
      <DialogContent>
        <TextField
          label="Tytuł"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          fullWidth
          margin="normal"
          onBlur={validateTitle}
          error={titleError !== ""}
          helperText={titleError}
        />
        <TextField
          label="Data"
          type="datetime-local"
          value={date}
          onChange={(e) => setDate(e.target.value)}
          fullWidth
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
          onBlur={validateDate}
          error={dateError !== ""}
          helperText={dateError}
        />
        <TextField
          label="Opis"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          fullWidth
          margin="normal"
          multiline
          minRows={4}
          maxRows={8}
          style={{ marginBottom: 30 }}
        />
        <CirclePicker
          color={color}
          onChange={(newColor) => setColor(newColor.hex)}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleAddFiles}
          style={{ marginTop: 30 }}
        >
          Dodaj pliki
        </Button>
        <input
          type="file"
          multiple
          onChange={handleFileChange}
          style={{ display: "none" }}
          ref={fileInputRef}
        />
        <Box mt={1}>
          {selectedFiles.map((fileName) => (
            <Chip
              key={fileName}
              label={fileName}
              onDelete={() => {
                setSelectedFiles(
                  selectedFiles.filter((file) => file !== fileName),
                );
                setFiles(files.filter((file) => file.name !== fileName));
              }}
              style={{ marginRight: "4px" }}
            />
          ))}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Anuluj</Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          color="primary"
          disabled={title.trim().length === 0 || !date}
        >
          Dodaj
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AddEventModal;
