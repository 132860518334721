import React, { useEffect, useState, useContext } from "react";
import {
  Typography,
  Box,
  Paper,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Link,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { fontSize, styled } from "@mui/system";
import Event from "./Event";
import api from "../../api/axios";
import { useParams, Link as RouterLink } from "react-router-dom";
import { parseLocalDate } from "../../formatDateTime";
import upcomingEventsIcon from "../../assets/upcoming_events.png";
import pastEventsIcon from "../../assets/past_events.png";

import AlertContext from "../../context/AlertContext";

const StyledPubId = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    fontSize: "2rem",
  },
}));

const NotFoundContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "60vh",
  textAlign: "center",
}));

const EventListContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: theme.spacing(4),

  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(2, 1, 2, 1),
  },
}));

const EventSection = styled(Paper)(({ theme }) => ({
  width: "100%",
  padding: theme.spacing(2),
  marginBottom: theme.spacing(4),

  [theme.breakpoints.down("sm")]: {
    marginBottom: theme.spacing(0),
  },
}));

const PastEventSection = styled(Paper)(({ theme }) => ({
  width: "100%",
  marginBottom: theme.spacing(4),

  [theme.breakpoints.down("sm")]: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(1),
  },
}));

const StyledIcon = styled("img")(({ theme }) => ({
  width: "24px",
  height: "24px",
  marginRight: "10px",

  [theme.breakpoints.down("sm")]: {
    width: "20px",
    height: "20px",
    marginRight: "10px",
  },
}));

const StyledEventTitle = styled(Typography)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  fontWeight: "bold",
  userSelect: "none",

  [theme.breakpoints.down("sm")]: {
    fontSize: "1.4rem",
  },
}));

function PublicEventList() {
  const { setAlert } = useContext(AlertContext);
  const { publicId } = useParams();
  const [events, setEvents] = useState([]);
  const [groupName, setGroupName] = useState([]);
  const [notFound, setNotFound] = useState(false);

  useEffect(() => {
    const fetchPublicEvents = async () => {
      try {
        const response = await api.get(`/api/public/${publicId}`);
        setEvents(response.data.Events);
        setGroupName(response.data.pubId);
        setNotFound(false);
      } catch (error) {
        if (error.response && error.response.status === 404) {
          setNotFound(true);
          setAlert([true, "Publiczny link nie istnieje!", "error"]);
        }
        console.error("Error fetching public events:", error);
      }
    };

    fetchPublicEvents();
  }, [publicId]);

  const upcomingEvents = events
    .filter((event) => new Date(parseLocalDate(event.dateTime)) > new Date())
    .sort((a, b) => new Date(parseLocalDate(a.dateTime)) - new Date(parseLocalDate(b.dateTime)));

  const pastEvents = events
    .filter((event) => new Date(parseLocalDate(event.dateTime)) <= new Date())
    .sort((a, b) => new Date(parseLocalDate(b.dateTime)) - new Date(parseLocalDate(a.dateTime)));

  if (notFound) {
    return (
      <NotFoundContainer>
        <Typography variant="h4" gutterBottom>
          Strona nie została znaleziona
        </Typography>
        <Typography variant="body1" gutterBottom>
          Wystąpił błąd podczas ładowania publicznej strony grupy.
        </Typography>
        <Typography variant="body1">
          Wróć do{" "}
          <Link component={RouterLink} to="/">
            strony głównej
          </Link>
          .
        </Typography>
      </NotFoundContainer>
    );
  }

  return (
    <EventListContainer>
      <StyledPubId
        variant="h3"
        gutterBottom
        style={{ marginBottom: 40, userSelect: "none" }}
      >
        <b>{groupName}</b>
      </StyledPubId>

      <EventSection elevation={0} sx={{ backgroundColor: "transparent" }}>
        <StyledEventTitle
          variant="h5"
          gutterBottom
          sx={{ marginBottom: { xs: 3, sm: 4 } }}
        >
          <StyledIcon src={upcomingEventsIcon} alt="Upcoming Events Icon" />
          Nadchodzące wydarzenia
        </StyledEventTitle>

        {upcomingEvents.map((event) => (
          <Event key={event.ID} event={event} eventReadOnly={true} />
        ))}
      </EventSection>

      <PastEventSection
        elevation={0}
        sx={{ backgroundColor: "transparent", marginTop: 4 }}
      >
        <Accordion sx={{ backgroundColor: "#fff", boxShadow: "none" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            sx={{ fontWeight: "bold" }}
          >
            <StyledEventTitle variant="h5" gutterBottom>
              <StyledIcon src={pastEventsIcon} alt="Past Events Icon" />
              Wydarzenia z przeszłości
            </StyledEventTitle>
          </AccordionSummary>
          <AccordionDetails>
            {pastEvents.map((event) => (
              <Event key={event.ID} event={event} eventReadOnly={true} />
            ))}
          </AccordionDetails>
        </Accordion>
      </PastEventSection>
    </EventListContainer>
  );
}

export default PublicEventList;
