import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const InfoAlert = ({ alert, setAlert }) => {
  const handleHideAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setAlert([false, alert[1], alert[2]]);
  };

  return (
    <Snackbar open={alert[0]} autoHideDuration={2500} onClose={handleHideAlert}>
      <Alert severity={alert[2]}>{alert[1]}</Alert>
    </Snackbar>
  );
};

export default InfoAlert;