import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    mode: 'light',
    background: {
      default: '#f8f9fa',
    },
    primary: {
      main: '#007aff',
    },
    secondary: {
      main: '#ff3b30',
    },
    text: {
      primary: '#1c1c1e',
      secondary: '#6e6e73',
      other: '#2a2a2e'
    },
  },
  typography: {
    fontFamily: 'San Francisco, Arial, sans-serif',
    h5: {
      fontWeight: 600,
    },
    body1: {
      fontSize: '1rem',
      fontWeight: 400,
    },
    body2: {
      fontSize: '0.875rem',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
        },
      },
    },
  },
});

export default theme;
