import React, { useState, useEffect } from "react";
import { Typography, Card } from "@mui/material";
import AttachmentIcon from "@mui/icons-material/Attachment";
import { styled } from "@mui/system";
import EventDetails from "./EventDetails";
import { formatDateTime, parseLocalDate } from "../../formatDateTime";

const EventCard = styled(Card)(({ theme, ispast }) => ({
  backgroundColor: theme.palette.background.paper,
  marginBottom: theme.spacing(2.5),
  borderRadius: "10px",
  boxShadow: "0 3px 10px rgba(0, 0, 0, 0.08)",
  padding: theme.spacing(2.5),
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  cursor: "pointer",
  transition: "transform 0.2s ease-in-out",
  opacity: ispast ? 0.75 : 1,
  filter: ispast ? "grayscale(50%)" : "none",
  "&:hover": {
    transform: "scale(1.01)",
    boxShadow: "0 4px 12px rgba(0, 0, 0, 0.15)",
  },

  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(2),
  },
}));

const EventDetailsContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "6px",

  [theme.breakpoints.down("sm")]: {
    gap: "4px",
  },
}));

const EventTitle = styled(Typography)(({ theme }) => ({
  fontSize: "1.2rem",
  fontWeight: 500,
  color: theme.palette.text.primary,
  userSelect: "none",
  [theme.breakpoints.down("sm")]: {
    fontSize: "0.95rem!important",
  },
}));

const EventDate = styled(Typography)(({ theme }) => ({
  fontSize: "0.9rem",
  color: theme.palette.text.secondary,
  marginTop: theme.spacing(0.4),

  [theme.breakpoints.down("sm")]: {
    fontSize: "0.85rem",
  },
}));

const EventCountdown = styled(Typography)(({ theme }) => ({
  fontWeight: "bold",
  color: theme.palette.primary.main,
  fontSize: "0.95rem",
  userSelect: "none",
  [theme.breakpoints.down("sm")]: {
    fontSize: "0.85rem!important",
  },
}));

const EventIcons = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
  gap: "10px",
}));

const EventColorDot = styled("div")(({ color, theme }) => ({
  width: "15px",
  height: "15px",
  borderRadius: "50%",
  backgroundColor: color,

  [theme.breakpoints.down("sm")]: {
    width: "10px",
    height: "10px",
  },
}));

function Event({ event, onUpdateEvents, eventReadOnly }) {
  const [open, setOpen] = useState(false);
  const [countdown, setCountdown] = useState("");

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const calculateCountdown = () => {
      const eventDate = new Date(parseLocalDate(event.dateTime));
      const currentDate = new Date();
      
      const timeDiff = eventDate.getTime() - currentDate.getTime();

      if (timeDiff <= 0) {
        setCountdown("Wydarzenie zakończone");
      } else if (timeDiff > 24 * 60 * 60 * 1000) {
        const eventDateWithoutTime = new Date(
          eventDate.getFullYear(),
          eventDate.getMonth(),
          eventDate.getDate(),
        );
        const currentDateWithoutTime = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          currentDate.getDate(),
        );
        const timeDiff =
          eventDateWithoutTime.getTime() - currentDateWithoutTime.getTime();
        const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
        setCountdown(`${days} dni do wydarzenia`);
      } else if (
        timeDiff <= 24 * 60 * 60 * 1000 &&
        eventDate.getDate() !== currentDate.getDate()
      ) {
        setCountdown("Jutro");
      } else {
        const hours = Math.floor(timeDiff / (1000 * 60 * 60));
        const minutes = Math.floor((timeDiff / (1000 * 60)) % 60);

        if (hours > 0) {
          setCountdown(`${hours}h ${minutes}m do wydarzenia`);
        } else {
          setCountdown(`${minutes}m do wydarzenia`);
        }
      }
    };

    calculateCountdown();
    const timer = setInterval(calculateCountdown, 1000);

    return () => {
      clearInterval(timer);
    };
  }, [event.dateTime]);

  const isPastEvent = new Date(parseLocalDate(event.dateTime)) <= new Date();

  return (
    <>
      <EventCard onClick={handleOpen} ispast={isPastEvent ? 1 : 0}>
        <EventDetailsContainer>
          <EventTitle>{event.title}</EventTitle>
          <EventDate>{formatDateTime(event.dateTime)}</EventDate>
          <EventCountdown>{countdown}</EventCountdown>
        </EventDetailsContainer>
        <EventIcons>
          {event.files.length > 0 && <AttachmentIcon />}
          <EventColorDot color={event.color} />
        </EventIcons>
      </EventCard>
      {open && (
        <EventDetails
          event={event}
          open={open}
          onClose={handleClose}
          onUpdateEvents={onUpdateEvents}
          isReadOnly={eventReadOnly}
        />
      )}
    </>
  );
}

export default Event;
