import React, { useState, useEffect, useContext } from "react";
import {
  Typography,
  TextField,
  Button,
  Box,
  Paper,
  List,
  ListItem,
  ListItemText,
  Divider,
} from "@mui/material";
import { jwtDecode } from "jwt-decode";

import { fontSize, styled } from "@mui/system";
import api from "../../api/axios";
import AlertContext from "../../context/AlertContext";
import useAuth from "../../hooks/Auth";
import User from "../../models/User";

const PublicLinkContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.success.light,
  padding: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  cursor: "pointer",
  marginBottom: theme.spacing(2),
  transition: theme.transitions.create(["background-color", "transform"], {
    duration: theme.transitions.duration.short,
  }),
  "&:hover": {
    backgroundColor: theme.palette.success.main,
    transform: "scale(1.01)",
  },

  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(1),
  },
}));

const StyledSectionTitle = styled(Typography)(({ theme }) => ({
  userSelect: "none",
  [theme.breakpoints.down("sm")]: {
    fontSize: "1.1rem",
  },
}));

const PublicLink = styled(Typography)(({ theme }) => ({
  color: theme.palette.success.contrastText,
  wordBreak: "break-all",
  [theme.breakpoints.down("sm")]: {
    fontSize: "0.9rem",
  },
}));

const SettingsContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: theme.spacing(4),

  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(1.5),
  },
}));

const SettingsSection = styled(Paper)(({ theme }) => ({
  width: "100%",
  padding: theme.spacing(2),
  marginBottom: theme.spacing(4),

  [theme.breakpoints.down("sm")]: {
    marginBottom: theme.spacing(1),
  },
}));

const DeleteAccountButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.error.main,
  color: theme.palette.error.contrastText,
  "&:hover": {
    backgroundColor: theme.palette.error.dark,
  },
}));

function Settings() {
  const { setUser } = useAuth();

  const [pubId, setPubId] = useState("");
  const [group, setGroup] = useState(null);
  const [publicLink, setPublicLink] = useState("");

  const [newEmail, setNewEmail] = useState("");
  const [emailError, setEmailError] = useState("");

  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");

  const { setAlert } = useContext(AlertContext);

  useEffect(() => {
    const fetchPublicLink = async () => {
      try {
        const response = await api.get("/api/groups/public");
        setPublicLink(response.data.publicLink);
      } catch (error) {
        console.error("Error fetching public link:", error);
      }
    };

    const fetchGroupData = async () => {
      try {
        const response = await api.get("/api/groups/me");
        setGroup(response.data);
        fetchPublicLink();
      } catch (error) {
        console.error("Error fetching group data:", error);
      }
    };

    fetchGroupData();
  }, [pubId]);

  const handleShareGroup = async () => {
    try {
      const response = await api.post("/api/groups/share");
      if (response.status === 200) {
        setPublicLink(response.data.publicLink);
        setAlert([true, "Udostępniono grupę!", "success"]);
      }
    } catch (error) {
      console.error("Error sharing group:", error);
      setAlert([true, "Wystąpił błąd podczas udostępniania grupy!", "error"]);
    }
  };

  const handleUnshareGroup = async () => {
    try {
      await api.delete("/api/groups/share");
      setPublicLink("");
      setAlert([true, "Usunięto publiczną grupę!", "success"]);
    } catch (error) {
      console.error("Error unsharing group:", error);
      setAlert([
        true,
        "Wystąpił błąd podczas usuwania publicznej grupy!",
        "error",
      ]);
    }
  };

  const handleJoinGroup = async () => {
    if (pubId.trim().length !== 0) {
      try {
        const response = await api.post("/api/groups/join", { pubId });
        if (response.status === 200) {
          const { token } = response.data;
          localStorage.setItem("JWT", token);
          setAlert([true, "Dołączono do grupy!", "success"]);
          setPubId("");
        } else {
          setAlert([
            true,
            "Wystąpił błąd podczas dołączania do grupy.",
            "error",
          ]);
        }
      } catch (error) {
        console.error("Błąd podczas dołączania do grupy:", error);
        setAlert([true, "Wystąpił błąd podczas dołączania do grupy.", "error"]);
      }
    }
  };

  const handleDeleteAccount = async () => {
    if (
      window.confirm(
        "Czy na pewno chcesz usunąć swoje konto? Ta akcja jest nieodwracalna.",
      )
    ) {
      try {
        await api.delete("/api/users/me");
        localStorage.removeItem("JWT");
        setPubId("");
        window.location.reload();
      } catch (error) {
        console.error("Error deleting account:", error);
      }
    }
  };

  const handleCopyPublicLink = () => {
    navigator.clipboard.writeText(publicLink).then(
      () => {
        setAlert([true, "Link skopiowany do schowka!", "success"]);
      },
      (err) => {
        setAlert([true, "Błąd podczas kopiowania linku.", "error"]);
      },
    );
  };

  const validateEmail = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(newEmail)) {
      setEmailError("Nieprawidłowy format adresu email");
    } else {
      setEmailError("");
    }
  };

  const handleChangeEmail = async () => {
    validateEmail();

    if (emailError === "" && newEmail.trim().length !== 0) {
      try {
        const response = await api.put("/api/users/email", { newEmail });
        if (response.status === 200) {
          const { token } = response.data;
          localStorage.setItem("JWT", token);
          const decodedToken = jwtDecode(token);
          const userInstance = new User(decodedToken.email, decodedToken.pubId);
          setUser(userInstance);
          setAlert([true, "Adres email został zmieniony!", "success"]);
          setNewEmail("");
        }
      } catch (error) {
        console.error("Error changing email:", error);
        if (error.response.status === 409) {
          setAlert([
            true,
            "Użytkownik o podanym adresie email już istnieje.",
            "error",
          ]);
        } else {
          setAlert([
            true,
            "Wystąpił błąd podczas zmiany adresu email.",
            "error",
          ]);
        }
      }
    }
  };

  const handleChangePassword = async () => {
    if (currentPassword.trim().length >= 5 && newPassword.trim().length >= 5) {
      try {
        const response = await api.put("/api/users/password", {
          currentPassword,
          newPassword,
        });
        if (response.status === 200) {
          setAlert([true, "Hasło zostało zmienione!", "success"]);
          setCurrentPassword("");
          setNewPassword("");
        }
      } catch (error) {
        if (error.response.status === 401) {
          setAlert([true, "Nieprawidłowe (obecne) hasło.", "error"]);
        } else {
          console.error("Error changing password:", error);
          setAlert([true, "Wystąpił błąd podczas zmiany hasła.", "error"]);
        }
      }
    }
  };

  return (
    <SettingsContainer>
      <Typography variant="h4" gutterBottom style={{ userSelect: "none" }}>
        Ustawienia
      </Typography>

      <SettingsSection elevation={3}>
        <StyledSectionTitle variant="h6" gutterBottom>
          Dołącz do grupy lub zmień grupę
        </StyledSectionTitle>
        <TextField
          label="PubID grupy"
          value={pubId}
          onChange={(e) => setPubId(e.target.value)}
          fullWidth
          margin="normal"
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleJoinGroup}
          disabled={pubId.trim().length === 0}
        >
          Dołącz/Zmień grupę
        </Button>
      </SettingsSection>

      {group && (
        <>
          <SettingsSection elevation={3}>
            <StyledSectionTitle variant="h6" gutterBottom>
              Udostępnij panel grupy {group.pubId}
            </StyledSectionTitle>
            {publicLink ? (
              <>
                <PublicLinkContainer onClick={handleCopyPublicLink}>
                  <PublicLink variant="body1">{publicLink}</PublicLink>
                </PublicLinkContainer>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleUnshareGroup}
                >
                  Usuń udostępnianie
                </Button>
              </>
            ) : (
              <Button
                variant="contained"
                color="primary"
                onClick={handleShareGroup}
              >
                Udostępnij
              </Button>
            )}
          </SettingsSection>

          <SettingsSection elevation={3}>
            <StyledSectionTitle variant="h6" gutterBottom>
              {group.pubId} ({group.Users.length} użytkowników)
            </StyledSectionTitle>
            <Typography
              variant="subtitle1"
              gutterBottom
              style={{ marginTop: 20 }}
            >
              Członkowie grupy:
            </Typography>
            <List>
              {group.Users.map((user, index) => (
                <React.Fragment key={user.ID}>
                  <ListItem>
                    <ListItemText primary={user.email} />
                  </ListItem>
                  {index < group.Users.length - 1 && <Divider />}
                </React.Fragment>
              ))}
            </List>
          </SettingsSection>
        </>
      )}

      <SettingsSection elevation={3}>
        <StyledSectionTitle variant="h6" gutterBottom>
          Zmień adres email
        </StyledSectionTitle>
        <TextField
          label="Nowy adres email"
          value={newEmail}
          onChange={(e) => setNewEmail(e.target.value)}
          fullWidth
          margin="normal"
          onBlur={validateEmail}
          error={emailError !== ""}
          helperText={emailError}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleChangeEmail}
          disabled={newEmail.trim().length === 0 || emailError !== ""}
        >
          Zmień adres email
        </Button>
      </SettingsSection>

      <SettingsSection elevation={3}>
        <StyledSectionTitle variant="h6" gutterBottom>
          Zmień hasło
        </StyledSectionTitle>
        <TextField
          label="Aktualne hasło"
          type="password"
          value={currentPassword}
          onChange={(e) => setCurrentPassword(e.target.value)}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Nowe hasło"
          type="password"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          fullWidth
          margin="normal"
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleChangePassword}
          disabled={
            newPassword.trim().length < 5 || currentPassword.trim().length < 5
          }
        >
          Zmień hasło
        </Button>
      </SettingsSection>

      <SettingsSection elevation={3}>
        <StyledSectionTitle variant="h6" gutterBottom>
          Usuwanie konta
        </StyledSectionTitle>
        <DeleteAccountButton variant="contained" onClick={handleDeleteAccount}>
          Usuń konto
        </DeleteAccountButton>
      </SettingsSection>
    </SettingsContainer>
  );
}

export default Settings;
